import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import Stories from '../../components/Stories'
import WritingPosts from '../../components/WritingPosts'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/writing/books.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: '0.5rem 0 0 #ffc600, -0.5rem 0 0 #ffc600',
              backgroundColor: 'black',
              color: '#ffc600',
              padding: '1rem',
            }}
          >
            Kwyx& Writing
          </h1>
        </div>
        <section className="section">
          <Helmet title={`writing | kwyx&`} />
          <div className="container">
            <div className="content">
              <h3 className="has-text-weight-semibold is-size-2">
                Stories
              </h3>
              <Stories />
              <h3 className="has-text-weight-semibold is-size-2">
                Posts
              </h3>
              <WritingPosts />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
